import { Carousel } from 'react-responsive-carousel';
import slide2 from './images/terrace.webp';
import slide3 from './images/ground_floor.webp';
import slide4 from './images/ham_machine.webp';
import slide5 from './images/wine_cellar.webp';
import slide6 from './images/first_floor.webp';
import FontAwesome from '../FontAwesome/';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './styles/css/carousel.css';

const CustomCarousel = () => {
  const carouselItems = [
    {
      id: 6,
      src: slide6,
      alt: 'Photo du restaurant'
    },
    {
      id: 4,
      src: slide4,
      alt: 'Photo de la cuisine'
    },
    {
      id: 3,
      src: slide3,
      alt: 'Photo de la cuisine'
    },
    {
      id: 5,
      src: slide5,
      alt: 'Photo de la cuisine'
    },
    {
      id: 2,
      src: slide2,
      alt: 'Photo de la cuisine'
    }
  ];

  return (
    <Carousel
      className="carousel"
      showArrows={true}
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={5000}
      transitionTime={250}
      swipeable={false}
      renderArrowPrev={(onClickHandler, hasPrev, label) =>
        hasPrev && (
          <button type="button" onClick={onClickHandler} title={label} className="carousel__arrows carousel__arrows--left">
            <FontAwesome icon="chevron_left" />
          </button>
        )
      }

      renderArrowNext={(onClickHandler, hasNext, label) =>
        hasNext && (
          <button type="button" onClick={onClickHandler} title={label} className="carousel__arrows carousel__arrows--right">
            <FontAwesome icon="chevron_right" />
          </button>
        )
      }
    >
      {carouselItems.map((item) => (
        <img key={item.id} src={item.src} alt={item.alt} className="carousel__image" />
      ))}
    </Carousel>
  );
};

export default CustomCarousel;
