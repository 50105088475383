import React, { useState, useEffect } from 'react';

const CookieConsent = ({ onAccept }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (document.cookie.includes('cookieConsent=true')) {
      setIsVisible(false);
      onAccept();
    }
  }, [onAccept]);

  const acceptCookies = () => {
    setIsVisible(false);
    document.cookie = "cookieConsent=true; path=/; max-age=31536000";
    onAccept();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div id="cookie-banner" style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      width: '100%',
      backgroundColor: '#333',
      color: '#fff',
      padding: '10px',
      textAlign: 'center',
      zIndex: 1000,
    }}>
      Ce site utilise des cookies pour améliorer votre expérience.
      <button onClick={acceptCookies} style={{
        backgroundColor: '#fff',
        color: '#333',
        border: 'none',
        padding: '5px 10px',
        cursor: 'pointer',
      }}>Accepter</button>
    </div>
  );
};

export default CookieConsent;
