import portrait1 from './images/265A1170.jpg';
import portrait2 from './images/265A1150.jpg';
import table from './images/IMG_2510.jpg';
import './styles/css/presentation.css';

function Presentation() {
    return (
        <section className="presentation section">
            <img className="presentation__portrait1" src={portrait1} alt="Photo du chef préparant les oeufs à la truffle" loading="lazy" />
            <img className="presentation__portrait2" src={portrait2} alt="Photo de la machine à couper le jambon" loading="lazy" />
            <article className="presentation__article italic">
                <p>
                    <strong>La Table d'Hôtes</strong> est un établissement chaleureux, convivial,
                    un lieu humain où il fait bon de partager, échanger,
                    savourer une cuisine raffinée de tradition à un excellent rapport qualité-prix.
                </p>
                <p>
                    Un lieu de rencontre, de convivialité avec un service attentionné,
                    un personnel aux petits soins qui vous garantit le sourire,
                    la bonne humeur et des conseils avisés dans la sélection de vos plats et vos vins.
                </p>
                <p>
                    Le <strong>restaurant</strong> est placé au coeur de la ville de <strong>Cannes</strong>,
                    à 5 mètres de la <strong>Rue d'Antibes</strong> et à 100 mètres de la <strong>Croisette</strong>.
                    <strong>La Table d'Hôtes</strong> est plus qu'un restaurant c'est une expérience à vivre à <strong>Cannes</strong>.
                </p>
            </article>
            <img className="presentation__duo" src={table} alt="Photo du chef et du directeur" loading="lazy" />
        </section>
    );
};

export default Presentation;
