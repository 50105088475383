import React, { useState } from 'react';
import CookieConsent from '../CookieConsent/';

const Map = () => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const handleCookieAccept = () => {
    setIsMapLoaded(true);
  };

  return (
    <div className="footer__article--map" style={{ width: '30%', height: '100%' }}>
      {isMapLoaded && (
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2891.587951205882!2d7.022172776631898!3d43.55263045892896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ce81ccf98a01e7%3A0x708546c29a052350!2zTGEgdGFibGUgZOKAmWjDtHRlcyBDYW5uZXM!5e0!3m2!1sfr!2sfr!4v1723397502126!5m2!1sfr!2sfr"
          height="100%"
          width="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      )}
      <CookieConsent onAccept={handleCookieAccept} />
    </div>
  );
};

export default Map;
