import Logo from '../../assets/images/logo_orange.svg';
import { Link } from 'react-router-dom';
import FontAwesome from '../FontAwesome/';
import MapComponent from '../Map/';
import './styles/css/footer.css'

function Footer() {
    return (
        <footer id="footer" className="footer section">
            <h2 className="footer__title">Une ambiance conviviale et inoubliable</h2>
            <article className="footer__article">
                <MapComponent />
                <div className="footer__article--infos">
                    <div>
                        <h3>Adresse</h3>
                        <a
                            href="https://maps.app.goo.gl/T8xVM8X77KBtKbGT7"
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <strong>3 Boulevard<br />de la République,<br />06400 Cannes</strong>
                        </a>
                    </div>
                    <div>
                        <h3>Horaires</h3>
                        <p>Du lundi au samedi</p>
                        <p>9h à 15h</p>
                        <p>19h à 22h30</p>
                    </div>
                    <div>
                        <h3>Contact</h3>
                        <a href="tel:0493397936">04.93.39.79.36</a>
                        <a href="mailto:tabledhotes@orange.fr">tabledhotes@orange.fr</a>
                    </div>
                    <div>
                        <h3>Suivez-nous!</h3>
                        <div className="follow">
                            <a
                                href="https://www.instagram.com/latabledhotescannes/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <FontAwesome icon="instagram" />
                            </a>
                            <a
                                href="https://www.facebook.com/people/La-Table-dH%C3%B4tes/61562642379237/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <FontAwesome icon="facebook" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer__legalnotice">
                    <Link to="/legal-notice">Mentions Légales</Link>
                    <p>&copy; {new Date().getFullYear()} La Table d'Hôtes. Tous droits réservés.</p>
                </div>
                <img className="footer__article--logo" src={Logo} alt="Logo du restaurant La Table d'Hôtes" />
            </article>
        </footer>
    );
};

export default Footer;
