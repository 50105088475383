import React, { useState } from 'react';
import FontAwesome from '../FontAwesome/';
import LanguageSwitcher from '../LanguageSwitcher';
import './styles/css/header.css';

function Header() {
    const [isNavVisible, setNavVisible] = useState(false);

    const toggleNav = () => {
        setNavVisible(!isNavVisible);
    };

    return (
        <header className="header">
            <LanguageSwitcher />
            <a
                className="header__location"
                href="https://maps.app.goo.gl/T8xVM8X77KBtKbGT7"
                target="_blank"
                rel="noreferrer noopener"
            >
                <FontAwesome icon="location" /> <strong>3 Boulevard de la République, 06400 Cannes</strong>
            </a>
            <a href="#hero">
                <h1 className="header__title">La Table d'Hôtes</h1>
            </a>
            <button className="header__toggle" onClick={toggleNav}>
                <FontAwesome icon="bars" />
            </button>
            <nav className={`header__nav ${isNavVisible ? "header__nav--visible" : ""}`}>
                <a href="#footer">
                    <FontAwesome icon="phone" />
                </a>
                <a href="mailto:tabledhotes@orange.fr">
                    <FontAwesome icon="envelope" />
                </a>
                <a
                    href="https://www.instagram.com/latabledhotescannes/"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <FontAwesome icon="instagram" />
                </a>
                <a
                    href="https://www.facebook.com/people/La-Table-dH%C3%B4tes/61562642379237/"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <FontAwesome icon="facebook" />
                </a>
            </nav>
        </header>
    );
};

export default Header;
