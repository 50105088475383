import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FontAwesome from './components/FontAwesome/';
import Header from './components/Header/';
import Hero from './sections/Hero/';
import Presentation from './sections/Presentation/';
import Menu from './sections/Menu/';
import Footer from './components/Footer/';
import LegalNotice from './components/LegalNotice/';
import './assets/styles/css/index.css';
import './components/I18n/';

const App = () => {
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const handleOrientationChange = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width > height && width < 1024) {
        setIsLandscape(true);
      } else {
        setIsLandscape(false);
      }
    };

    handleOrientationChange();

    window.addEventListener('resize', handleOrientationChange);
    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <Router>
      {isLandscape && (
        <div className="landscape-warning">
          <p>Veuillez tourner votre appareil en mode portrait.</p>
        </div>
      )}
      <Header />
      <div className="container">
        <Routes>
          <Route path="/" element={
            <>
              <Hero className="section" />
              <Presentation className="section" />
              <Menu className="section" />
              <Footer className="section" />
              <a className="container__up" href="#hero">
                <FontAwesome icon="chevron_up" />
              </a>
            </>
          } />
          <Route path="/legal-notice" element={<LegalNotice />} />
        </Routes>
      </div>
    </Router>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
