import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faBars, faChevronLeft, faChevronRight, faChevronUp, faLeaf, faLocationDot, faPlus, faSquareEnvelope, faSquarePhone } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faSquareFacebook } from '@fortawesome/free-brands-svg-icons';

const icons = {
    location: faLocationDot,
    phone: faSquarePhone,
    envelope: faSquareEnvelope,
    instagram: faInstagram,
    facebook: faSquareFacebook,
    chevron_left: faChevronLeft,
    chevron_right: faChevronRight,
    chevron_up: faChevronUp,
    bars: faBars,
    asterisk: faAsterisk,
    plus: faPlus,
    leaf: faLeaf
};

const FontAwesome = ({ icon }) => {
    return <FontAwesomeIcon icon={icons[icon]} />;
};

export default FontAwesome;
