// src/components/LegalNotice.js

import React from 'react';
import { Link } from 'react-router-dom';
import './styles/css/legalnotice.css';

const LegalNotice = () => {
  return (
    <div className="legal-notice">
      <h1 className="bold">Mentions Légales</h1>

      <h2 className="bold">Éditeur du Site</h2>
      <p>Ce site est édité par La Table d'Hôtes.</p>

      <h2 className="bold">Hébergeur du Site</h2>
      <p>
        <strong>Nom de l'hébergeur :</strong> o2switch<br />
        <strong>Adresse :</strong> Chem. des Pardiaux, 63000 Clermont-Ferrand<br />
        <strong>Téléphone :</strong> 04 44 44 60 40<br />
        <strong>Site web :</strong> <a href="https://www.o2switch.fr/" target="_blank" rel="noopener noreferrer">https://www.o2switch.fr/</a>
      </p>

      <h2 className="bold">Propriété Intellectuelle</h2>
      <p>
        Tous les éléments du site La Table d'Hôtes - Cannes, qu'ils soient visuels ou sonores, y compris la technologie sous-jacente, sont protégés par le droit d'auteur, des marques ou des brevets. Ils sont la propriété exclusive de La Table d'Hôtes. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de La Table d'Hôtes. Toute exploitation non autorisée du site ou de l'un quelconque des éléments qu'il contient sera considérée comme constitutive d'une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
      </p>

      <h2 className="bold">Limitation de Responsabilité</h2>
      <p>
        Les informations contenues sur ce site sont aussi précises que possible et le site est périodiquement remis à jour, mais peut toutefois contenir des inexactitudes, des omissions ou des lacunes. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler par email, à l'adresse <a href="mailto:tabledhotes@orange.fr">tabledhotes@orange.fr</a>, en décrivant le problème de la manière la plus précise possible (page posant problème, type d'ordinateur et de navigateur utilisé, etc.).
      </p>

      <h2 className="bold">Données Personnelles</h2>
      <p>
        Conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, modifiée par la loi n°2004-801 du 6 août 2004, et au Règlement Général sur la Protection des Données (RGPD) du 27 avril 2016, les utilisateurs disposent d'un droit d'accès, de rectification, de suppression et d'opposition aux données personnelles les concernant. Pour exercer ce droit, il suffit d'adresser un courrier à <a href="mailto:tabledhotes@orange.fr">tabledhotes@orange.fr</a>.
      </p>

      <h2 className="bold">Cookies</h2>
      <p>
        Le site La Table d'Hôtes - Cannes peut être amené à vous demander l’acceptation des cookies pour des besoins de statistiques et d'affichage. Un cookie est une information déposée sur votre disque dur par le serveur du site que vous visitez. Il contient plusieurs données qui sont stockées sur votre ordinateur dans un simple fichier texte auquel un serveur accède pour lire et enregistrer des informations. Certaines parties de ce site ne peuvent être fonctionnelles sans l’acceptation de cookies.
      </p>

      <h2 className="bold">Litiges</h2>
      <p>
        Les présentes conditions du site La Table d'Hôtes - Cannes sont régies par les lois françaises et toute contestation ou litiges qui pourraient naître de l'interprétation ou de l'exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société La Table d'Hôtes. La langue de référence, pour le règlement de contentieux éventuels, est le français.
      </p>

      <Link to="/" className="back-button">Retour au site</Link>
    </div>
  );
};

export default LegalNotice;
