import Logo from './images/logo_white.svg';
import CustomCarousel from '../../components/Carousel/';
import Slate from '../../components/Slate/';
import './styles/sass/hero.min.css';

function Hero() {
    return (
        <section id="hero" className="hero">
            <CustomCarousel />
            <img className="hero__logo" src={Logo} alt="Logo La Table d'Hôtes" />
            <Slate />
            <nav className="hero__nav">
                <a className="bold" href="#menu">Notre carte</a>
                <a className="bold" href="#footer">Nous contacter</a>
            </nav>
        </section>
    );
};

export default Hero;
